import React  from 'react'
import SEO from "../components/seo"
import Layout from "../components/layout"
import PageHeader from '../components/PageHeader'
import styled from 'styled-components'
import {LeftSection, RightSection, PageWrapper, Row, Span, Flex, Title4, Title} from '../components/UI'
import {OutboundLink} from 'gatsby-plugin-gtag'
import girl from '../images/girl.jpg'
import pattern from '../images/pattern-contact.jpg'
import WhatsApp from '../svg/whatsapp.svg'
import {breakpoints} from '../utils/helper'

const MyRow = styled(Row)`
    //margin: 0 auto 30px;

    @media ${breakpoints.desktop} {
        padding: 30px 0 90px;
        width: 100%;
        max-width: 850px;
        margin: 0 auto;
    
        &.head {
            padding: 0 !important;
        }
    } 
    
    &.head {
        color: ${props => props.theme.colors.red};
    }
`

const MyLeftSection = styled(LeftSection)`
    margin-right: 30px;
`

const Address = styled(Flex)`
    flex-direction: column;
    
    @media ${breakpoints.desktop} {
        margin: 0 auto;
    }
`

const Link = styled(OutboundLink)`
    color: ${props => props.theme.colors.red};
    ${props => props.theme.fontStyle.h4};
    text-decoration: none;
    margin-bottom: 3px;
    font-size: 0.875rem;
`

const Line = styled(Flex)`
    flex-direction: row;
    font-size: 0.875rem;
    
    a {
        margin-left: 6px;
    }
`

const IconWrapper = styled.div`
    margin: -4px 2px 0 4px;
`

const Image = styled.img`
    width: 100%;
    max-width: 850px;
`

const ContactPage = ({location}) =>
    <Layout location={location}>
        <SEO title="Contact | Neem bij een vraag of klacht contact op met UP Carwash"
             description="Neem contact op met UP Carwash als je een vraag, klacht, opmerking of een goed idee met ons wilt delen. We staan je graag te woord!"/>
        <PageHeader backgroundColor="#ffffff" textColor="#FF3A00" imageWidth="520px" pattern={pattern} minHeight='206px'/>
        <PageWrapper>
            <MyRow className="head">
                <Title>Contact</Title>
            </MyRow>
            <MyRow>
                <MyLeftSection>
                    <Span>
                        Heb je een vraag? 80% van de mensen vindt het antwoord direct op onze <Link
                        href="http://aandacht.upcarwash.nl" target="_blank" rel="noopener">meest gestelde
                        vragen</Link> pagina.
                        <br/>
                        <br/>
                        Staat jouw vraag er niet tussen of heb je een klacht, opmerking of goed idee? Neem dan vooral
                        contact met ons op. We staan je graag te woord!
                        <br/>
                        <br/>
                        Onze klantenservice is geopend van maandag t/m vrijdag van 9:00 tot 17:00.
                        We zijn te bereiken via:
                    </Span>
                    <br/>
                    <Line>WhatsApp <IconWrapper><WhatsApp/></IconWrapper>: <Link
                        href="https://wa.me/message/YISWJ6TNGV3BE1" rel="noopener">start chat</Link></Line>
                    <Line>Ons <Link href="https://aandacht.upcarwash.nl/hc/nl/requests/new" target="_blank" rel="noopener">contactformulier</Link></Line>
                    <Line>Telefonisch op<Link href="tel:+31857602403" rel="noopener">085 760 2403</Link></Line>
                </MyLeftSection>
                <RightSection>
                    <Address>
                        <Title4>
                            UP Carwash CO.<br/>
                            Postbus 303<br/>
                            1945 AH Beverwijk<br/>
                        </Title4>
                    </Address>
                </RightSection>
            </MyRow>
            <MyRow>
                <Image src={girl} alt="Dame"/>
            </MyRow>
        </PageWrapper>
    </Layout>

export default ContactPage
