import styled from 'styled-components'
import {Flex, Title} from './UI'
import React from 'react'
import { breakpoints } from '../utils/helper'

const Header = styled(Flex)`
    width: 100%;
    height: auto;
    min-height: ${props => props.minHeight || '120px'};
    ${props => props.pattern && `background: url(${props.pattern}) 50% 50% no-repeat`};
    background-color: ${props => props.backgroundColor};
    background-size: cover;
    justify-content: space-between;
    padding: 88px 0 24px;
    //margin: 64px 0 0;
    
    @media ${breakpoints.desktop} {
        padding: 0 0 24px;
        height: ${props => props.small ? '220px' : '420px'};
        margin: ${props => props.small ? '90px 0 0' : '90px 0'};
    }
`

const Inner = styled(Flex)`
    flex-direction: row;
    width: 100%;
    height: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 20px;
    align-items: center;
    position: relative;
    justify-content: space-between;

    h1 {
        width: 70%;
        white-space: pre-wrap;
        padding-top: 0px;
        
        @media ${breakpoints.tablet} {
            width: 50%;
        }
        
        @media ${breakpoints.desktop} {
            padding-top: 60px;
            margin-bottom: 18px;
        }
    }
    
    @media ${breakpoints.large} {
        padding: 0;
    }
`

const Image = styled.img`
    width: ${props => props.imageWidth};
    height: auto;
    position: absolute;
    right: 0px;
    top: 90px;
    overflow: hidden;
    display: none;
    
    @media ${breakpoints.desktop} {
        display: block;
    }
`

const PageHeader = ({title, image, backgroundColor, textColor, imageWidth, children, pattern, small, minHeight}) =>
    <Header backgroundColor={backgroundColor} pattern={pattern} small={small} minHeight={minHeight}>
        <Inner>
            {children || <Title color={textColor}>{title}</Title>}
            {image && <Image src={image} alt="carwash afbeelding" imageWidth={imageWidth}/>}
        </Inner>
    </Header>

export default PageHeader
